import React from "react";
const isBrowser = typeof window !== "undefined";
if (isBrowser) {
  if (window.location.pathname === "/") {
    window.location.pathname = `/en/`;
  }
}
const indexPage = () => <></>;

export default indexPage;
